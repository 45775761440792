import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './PostList.scss'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <div className="container">
        <div className="post-list-wrapper">
          {posts.map(({ node: post }) => (
            <div className="post-item columns" key={post.id}>
              <div className="column is-8 is-offset-2">
                {post.featured_media && (
                  <Img
                    fixed={post.featured_media.localFile.childImageSharp.fixed}
                  />
                )}
                <div className="post-content">
                  <p>
                    <Link to={post.slug}>
                      <h4 className="section-header">{post.title}</h4>
                    </Link>
                    <p className="date">{post.date}</p>
                  </p>
                  <div>
                    <div
                      className="excerpt"
                      dangerouslySetInnerHTML={{
                        __html: post.excerpt.replace(
                          /<p class="link-more.*/,
                          ''
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
  }
`
