import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList/PostList'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost

    return (
      <Layout color="dark" slug="blog">
        <SEO title="Blog" />
        <section className="section no-padding-bottom">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="page-intro has-text-centered">
                  <h1 className="subtitle is-size-2 is-size-3-mobile">Blog</h1>
                  <p className="is-size-4 is-size-5-mobile">
                    Latest posts from DH Designs
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section--gradient">
          <div className="container">
            {posts.length && (
              <div>
                <PostList posts={posts} title="Blog" />
                <Pagination pageContext={pageContext} pathPrefix="/" />
              </div>
            )}
          </div>
        </section>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
